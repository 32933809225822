#skip-navigation {
  position: absolute;
  display: block;
  background: $white;
  color: $brand-primary;
  padding: 15px;
  text-align: center;
  transform: translateY(-101%);
  transition: transform 0.25s ease-in-out;
  z-index: 10;

  &:focus {
    transform: translateY(0);
    transition: transform 0.25s ease-in-out;
  }
}

#modal {
  overflow: scroll;
  background-color: rgba($black, 0.5);

  .modal-title {
    color: $brand-primary;
    font-family: $secondary-font;
  }

  .modal-dialog {
    @include tablet {
      max-width: 75%;
    }

    @include hd {
      max-width: 50%;
    }
  }
}

body:not(.home) .banner {
  @include tablet {
    background-image: url('../images/interior-header.webp');
    background-position: center top;
    height: 300px;
  }

  @include laptop {
    height: 400px;
  }
}

.banner {
  background-color: $black;
  position: relative;
  z-index: 1;
  border-bottom: 5px solid $brand-primary;

  @include laptop {
    background-color: #666;
    border: 0;
  }

  .container {
    @include desktop {
      max-width: 990px;
    }
  }

  .brand {
    display: inline-block;
    background-color: rgba($black, 0.8);
    padding: 9px 12px 8px;
    max-width: 275px;

    @include laptop {
      max-width: 100%;
    }
  }

  nav {
    a {
      color: $white;
      text-transform: lowercase;
      font-weight: 500;
      letter-spacing: -0.2px;
    }

    a[href^="tel:"] {
      color: $white !important;
    }
  }

  .nav-top {
    padding: 10px 0;
    margin-left: 73px;
    flex-shrink: 0;

    li:hover,
    li.active {
      a {
        color: #eaabea;
      }
    }

    li {
      margin-right: 10px;
    }

    a {
      font-size: 20px;
    }
  }

  .nav-primary {
    position: relative;
    z-index: 1;
  }

  .nav-primary-container {
    position: relative;
    width: fit-content;
    padding: 0 5px;
    background-color: $brand-primary;

    &::before {
      content: '';
      position: absolute;
      display: block;
      border-radius: 2px 1px;
      background-clip: padding-box;
      background-color: #572d55;
      top: -9px;
      left: -20px;
      width: 50px;
      height: 65px;
      z-index: -1;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      background: $brand-primary;
      height: 5px;
      width: 633px;
      top: 42px;
      left: -30px;
      z-index: 0;
    }

    ul.nav {
      position: relative;
      z-index: 1;
    }

    a {
      font-size: 22px;
      padding: 1px 0 3px;
      margin: 0 10px;
      position: relative;

      @include links-transition();

      span {
        display: none;
      }
    }

    a::before {
      content: "";
      width: 100%;
      height: 5px;
      background-color: #f7f7f7;
      bottom: -5px;
      position: absolute;
      opacity: 0;
    }

    a:hover {
      text-shadow: 1px 1px 1px $black;

      &::before {
        opacity: 1;
      }
    }

    [aria-current="page"]::before {
      opacity: 1;
    }
  }
}
