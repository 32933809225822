.page-sidebar {
  @include laptop {
    max-width: 215px;
    margin-left: 14.5%;
  }

  p {
    font-size: 16px;
    line-height: 1.4;
    text-align: justify;
  }

  p:last-child {
    margin-bottom: 0;
  }

  hr {
    border-width: 2px;
    max-width: 140px;
    margin: 1.5rem auto;
  }

  .wp-block-buttons {
    margin-bottom: 26px;
    text-align: center;
  }

  .wp-block-button__link {
    text-align: center;
    margin: 0 auto 20px;
  }
}
