/*
* Global block styles
*/

//color palette
.has-black-background-color {
  background-color: #000 !important;
}

.has-black-color,
.has-black-color *:not(a) {
  color: #000;
}

.has-cyan-bluish-gray-background-color {
  background-color: #abb8c3 !important;
}

.has-cyan-bluish-gray-color,
.has-cyan-bluish-gray-color *:not(a) {
  color: #abb8c3;
}

.has-white-background-color {
  background-color: #fff !important;
}

.has-white-color,
.has-white-color *:not(a) {
  color: #fff;
}

.has-pale-pink-background-color {
  background-color: #f78da7 !important;
}

.has-pale-pink-color,
.has-pale-pink-color *:not(a) {
  color: #f78da7;
  border-color: #f78da7;
}

.has-vivid-red-background-color {
  background-color: #cf2e2e !important;
}

.has-vivid-red-color,
.has-vivid-red-color *:not(a) {
  color: #cf2e2e;
}

.has-luminous-vivid-orange-background-color {
  background-color: #ff6900 !important;
}

.has-luminous-vivid-orange-color,
.has-luminous-vivid-orange-color *:not(a) {
  color: #ff6900;
}

.has-luminous-vivid-amber-background-color {
  background-color: #fcb900 !important;
}

.has-luminous-vivid-amber-color,
.has-luminous-vivid-amber-color *:not(a) {
  color: #fcb900;
}

.has-light-green-cyan-background-color {
  background-color: #7bdcb5 !important;
}

.has-light-green-cyan-color,
.has-light-green-cyan-color *:not(a) {
  color: #7bdcb5;
}

.has-vivid-green-cyan-background-color {
  background-color: #00d084 !important;
}

.has-vivid-green-cyan-color,
.has-vivid-green-cyan-color *:not(a) {
  color: #00d084;
}

.has-pale-cyan-blue-background-color {
  background-color: #8ed1fc !important;
}

.has-pale-cyan-blue-color,
.has-pale-cyan-blue-color *:not(a) {
  color: #8ed1fc;
}

.has-vivid-cyan-blue-background-color {
  background-color: #0693e3 !important;
}

.has-vivid-cyan-blue-color,
.has-vivid-cyan-blue-color *:not(a) {
  color: #0693e3;
}

.has-vivid-purple-background-color {
  background-color: #9b51e0 !important;
}

.has-vivid-purple-color,
.has-vivid-cyan-blue-color *:not(a) {
  color: #9b51e0;
}

//paragraph styles
.has-small-font-size {
  font-size: 13px;
}

.has-medium-font-size {
  font-size: 20px;
}

.has-large-font-size {
  font-size: 36px;
}

.has-x-large-font-size {
  font-size: 42px;
}

.has-drop-cap::first-letter {
  color: $brand-primary;
  float: left;
  font-family: $heading-font;
  font-size: 75px;
  line-height: 45px;
  padding-top: 4px;
  padding-right: 8px;
  padding-left: 3px;
}

//alignment
.has-text-align-left {
  text-align: left;
}

.has-text-align-center,
.aligncenter {
  text-align: center;
}

.has-text-align-right {
  text-align: right;
}

/*
* Block: Image
*/
.wp-block-image {
  figcaption {
    margin-top: 1rem;
  }
}

.wp-block-image.is-style-rounded img {
  border-radius: 50%;
}

/*
* Block: Media & Text
*/
.wp-block-media-text {
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 1rem;
}

.wp-block-media-text.is-stacked-on-mobile {
  display: block;

  @include tablet {
    display: grid;
  }
}

.wp-block-media-text.is-image-fill figure {
  background-size: cover;
  min-height: 250px;

  @include tablet {
    min-height: 400px;
  }

  img {
    display: none;
  }
}

.wp-block-media-text.has-media-on-the-right {
  grid-auto-flow: dense;

  figure {
    grid-column: 2;
  }
}

.wp-block-media-text.is-vertically-aligned-center {
  align-items: center;
}

.wp-block-media-text.is-vertically-aligned-bottom {
  align-items: end;
}

/*
* Block: Columns
*/
.wp-block-columns {
  display: block;
  gap: 3rem;

  @include tablet {
    display: flex;
  }
}

.wp-block-columns.is-not-stacked-on-mobile {
  display: flex;
}

.wp-block-column {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  display: flex;
  flex-direction: column;
}

.wp-block-column.is-vertically-aligned-center {
  justify-content: center;
}

.wp-block-column.is-vertically-aligned-bottom {
  justify-content: flex-end;
}

/*
* Block: Quote
*/
.wp-block-quote {
  margin: 0 0 1rem 2rem;

  p::before,
  p::after {
    content: '"';
  }

  p {
    color: inherit;
    font-style: italic;
  }

  cite::before {
    content: '-';
  }
}

/*
* Block Group
*/

.wp-block-group {
  * {
    font-size: inherit;
  }
}

/*
* Block: Card
*/
.card-block {
  height: 100%;

  @include tablet {
    max-width: 215px;
  }

  .box {
    height: 100%;
    margin-bottom: 2.5rem;

    @include tablet {
      margin: 0 auto;
    }
  }

  .image-container {
    background-color: $white;
    width: 100%;
    height: 118px;
    display: flex;
    align-items: center;
    padding: 10px 20px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
