body.home .container {
  @include desktop {
    max-width: 990px;
  }
}

.icon {
  flex-shrink: 0;
  position: relative;
  background-color: $brand-primary;
  border: 3px solid $white;
  box-shadow: 0 0 0 3px $brand-primary;
  border-radius: 50%;
  height: 103px;
  width: 103px;
  margin: 3px auto 30px;

  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.about-home {
  position: relative;

  &::before {
    content: "ak.";
    font-size: 56px;
    font-weight: 500;
    color: #525252;
    text-align: center;
    display: block;
    border-radius: 84px 84px 0 0;
    width: 168px;
    height: 80px;
    position: absolute;
    top: -80px;
    left: 50%;
    transform: translateX(-50%);
    background: url('../images/bg-gray.webp') repeat;
    z-index: 1;

    @include laptop {
      transform: translateX(-55%);
    }
  }
}

.about-home .content p {
  font-size: 22px;
  color: #515151;
  font-weight: 500;
  line-height: 1.4;

  @include tablet {
    font-size: 30px;
  }
}

.about-home .images {
  &::before,
  &::after {
    content: "";
    display: block;
    height: 59px;
    width: 92%;
    max-width: 939px;
    margin: 0 auto;

    @include tablet {
      width: 100%;
    }
  }

  &::before {
    background: url('../images/before-curve.webp') top center no-repeat;
    margin-bottom: 30px;

    @include laptop {
      margin-bottom: 60px;
    }
  }

  &::after {
    background: url('../images/after-curve.webp') top center no-repeat;
    margin-top: 30px;

    @include laptop {
      margin-top: 60px;
    }
  }

  .image-container {
    max-width: 460px;
    overflow: hidden;
    position: relative;
    transition: all 0.3s ease-out;
  }

  img {
    max-width: 100%;
    height: auto;
    transition: all 0.3s ease-out;
  }

  .overlay {
    width: 100%;
    height: 100%;
    color: $white;
    background: rgba($black, 0.76);
    padding: 10px;
    position: absolute;
    top: 0;
    z-index: 1;
    transition: all 0.3s ease-out;

    @include laptop {
      opacity: 0;
    }
  }

  h3 {
    text-transform: uppercase;
    font-size: 30px;
    font-weight: 400;
    margin-bottom: 10px;
    color: $white;
  }

  p {
    color: $white;
    text-align: justify;
    font-size: 20px;
  }

  h3,
  p {
    position: relative;
    transition: all 0.3s ease-out;

    @include laptop {
      left: -100%;
    }
  }

  .image-container:hover {
    img {
      transform: scale(1.4);
    }

    .overlay {
      opacity: 1;
    }

    h3,
    p {
      left: 0;
    }
  }
}

.about-home .stylists {
  h3 {
    font-size: 35px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 10px;
    text-transform: lowercase;

    @include tablet {
      font-size: 45px;
      padding-top: 10px;
    }
  }
}

.features-home {
  box-shadow: 0 0 3px #3e3e3e;
  background-color: $green;

  @include laptop {
    padding: 40px 0 45px;
  }

  .header {
    margin-bottom: 55px;

    h2 {
      font-weight: 500;
      text-transform: uppercase;
      text-align: center;
      font-size: 40px;
      margin-bottom: 13px;
    }

    p {
      text-align: center;
      font-size: 18px;
      margin-bottom: 20px;
    }
  }

  .feature-row {
    margin-bottom: 1.8rem;

    @include tablet {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  .feature-col {
    @include tablet {
      width: 180px;
    }
  }

  h3 {
    font-size: 24px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 400;
    position: relative;
    margin-top: 28px;
    padding-top: 28px;
    border-top: 2px solid #98ad7c;

    &::before {
      content: '';
      display: block;
      width: 180px;
      height: 2px;
      background-color: $green;
      position: absolute;
      bottom: calc(100% + 28px);
      right: calc(50% - 90px);
    }
  }
}
