body.blog .page-header {
  display: none;
}

a.more-link {
  display: inline-block;
  padding: 4px 12px;
  font-size: 14px;
  line-height: 20px;
  color: #333;
  text-shadow: 0 1px 1px rgb(255, 255, 255, 75%);
  vertical-align: middle;
  cursor: pointer;
  background-image: linear-gradient(to bottom, #fff, #e6e6e6);
  background-repeat: repeat-x;
  border: 1px solid #bbb;
  border-radius: 4px;
  box-shadow: inset 0 1px 0 rgb(255, 255, 255, 20%), 0 1px 2px rgb(0, 0, 0, 5%);

  &:hover {
    background-image: linear-gradient(to bottom, #e6e6e6, #e6e6e6);
  }
}

.icon-chevron-right {
  display: inline-block;
  width: 14px;
  height: 14px;
  margin-top: 1px;
  vertical-align: text-top;
  background-image: url('../images/right-chevron.webp');
  background-position: 0;
  background-repeat: no-repeat;
}
