.sideNav {
  width: $sideNavWidth;
  #{$sideNavDirection}: -$sideNavWidth;
  box-shadow: inset 7px 0 9px -7px rgba(0, 0, 0, 0.4);
  position: fixed;
  z-index: 1000;
  height: 100%;
  top: 0;
  // background: $brand-primary;
  transition: all 0.3s ease;
  overflow-y: auto;
  padding: 10px 0;

  a {
    display: block;
    color: $body-color;
    font-size: 18px;
    font-weight: 500;
    padding: 9px 20px 9px 35px;
    text-transform: lowercase;
    position: relative;

    &::before {
      content: '';
      background: url('../images/pink-boxes.webp') no-repeat;
      display: block;
      width: 8px;
      height: 12px;
      position: absolute;
      left: 20px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &.sideNav-open {
    #{$sideNavDirection}: 0;

    @include desktop {
      #{$sideNavDirection}: -$sideNavWidth;
    }
  }
}

/* classes applied to the body */
.sideNavBody {
  overflow-x: hidden;
  position: relative;
  #{$sideNavDirection}: 0;
  transition: all 0.3s ease;
}

.sideNavBody-push {
  #{$sideNavDirection}: $sideNavWidth;

  @include desktop {
    #{$sideNavDirection}: 0;
  }
}
