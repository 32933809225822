footer.footer-panel {
  @include wide {
    padding: 75px 0 60px;
  }

  * {
    font-weight: 300;
  }

  .title-box {
    width: 100%;

    @include laptop {
      width: auto;
    }

    span {
      width: 106px;
      height: 106px;
      margin-bottom: 20px;
      font-size: 79px;
    }
  }

  .nav {
    margin-top: 22px;
    margin-bottom: -13px;

    @include tablet {
      margin-bottom: 0;
    }

    .hide-footer {
      display: none;
    }

    li a {
      position: relative;
      color: #7f7f7f;
      font-size: 25px;
      line-height: 2;

      @include tablet {
        padding-left: 20px;
        line-height: 125%;
      }

      &::before {
        @include tablet {
          content: '';
          background: url('../images/pink-boxes.webp') no-repeat;
          display: block;
          width: 8px;
          height: 12px;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      &:hover {
        color: #f0f;
      }
    }
  }

  .address {
    font-size: 20px;
    color: #7f7f7f;
    line-height: 2;
    margin-top: 30px;

    @include tablet {
      line-height: 1.3;
    }
  }

  .policies {
    margin-top: 35px;
    display: inline-block;
    font-size: 20px;
  }
}

footer.copyright {
  background-color: #787878;
  font-size: 12px;
  font-weight: 600;
  font-family: arial, helvetica, sans-serif;
  padding: 25px 0 20px;

  *,
  a:hover {
    color: #f7f7f7;
  }
}
