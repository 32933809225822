a[href^="tel:"] {
  color: $body-color;
}

a[href^="mailto:"] {
  &::after {
    content: '' !important;
  }
}

a[href$=".pdf"]::after {
  content: '\f1c1';
  margin-left: 7px;
}

.google-map {
  position: relative;
  overflow: hidden;
  flex-grow: 1;

  iframe {
    width: 100%;
    height: 100%;
    min-height: 400px;
    border: 0;
    position: relative;

    @include laptop {
      min-height: 300px;
    }
  }
}

.search-form {
  display: flex;
  flex-wrap: wrap;

  label {
    flex-grow: 1;
    margin-bottom: 0;
  }
}

.pagination-style {
  a,
  span {
    margin-right: 10px;
    text-decoration: none !important;
  }

  .nav-links {
    padding: 5px 10px 5px 0;
    border-radius: 0;
  }
}

.page-header {
  h1,
  .h1 {
    margin-bottom: 20px;
    text-transform: lowercase;
    letter-spacing: -0.2px;
  }
}

.page-content-panel {
  background-color: $white;
  padding: 20px 15px;
  border-radius: 5px;
  box-shadow: 0 0 3px #3e3e3e;
  position: relative;
  z-index: 1;

  @include tablet {
    padding: 40px;
    margin-top: -200px;
  }

  @include wide {
    max-width: 98%;
  }

  h2 {
    margin-bottom: 20px;
  }

  p {
    letter-spacing: 0.08px;
    margin-bottom: 1.2rem;
  }
}

.title-box {
  text-align: center;

  span {
    display: inline-block;
    width: 144px;
    height: 143px;
    border-radius: 72px;
    color: #7f7f7f;
    font-size: 107px;
    font-weight: 200 !important;
    border: 2px solid #7f7f7f;
    line-height: 116%;
    letter-spacing: -6px;
  }

  h2 {
    font-size: 44px;
    font-weight: 300;
    color: #7f7f7f;
  }
}

.testimonials-block {
  position: relative;
  margin-top: 100px;

  &::before {
    content: '';
    background-image: url('../images/quotes.webp');
    background-repeat: no-repeat;
    height: 300px;
    width: 300px;
    position: absolute;
    top: -25px;
    left: 20px;

    @include tablet {
      left: 52px;
    }
  }

  .testimonial {
    padding: 0 40px;

    @include tablet {
      padding: 0 75px;
    }

    p,
    span {
      font-size: 16px;
      letter-spacing: 0.2px;
    }
  }

  .slick-arrow {
    height: 30px;
    width: 26px;
    background-color: rgba($black, 0.5);
    border-radius: 2px;
    z-index: 2;
    top: 55px;

    @include tablet {
      height: 45px;
      width: 50px;
    }

    @include links-transition();

    &::before {
      font-family: $primary-font;
      font-weight: 300;
      font-size: 50px;
      color: $white;
      position: relative;
      top: -15px;
      opacity: 1;

      @include tablet {
        font-size: 60px;
      }
    }

    &.slick-prev {
      left: 0;

      &::before {
        content: '‹';
      }
    }

    &.slick-next {
      right: 0;

      &::before {
        content: '›';
      }
    }
  }

  .slick-arrow:hover {
    background-color: $black;
  }
}

.img-polaroid img {
  padding: 4px;
  background-color: $white;
  border: 1px solid #ccc;
  border: 1px solid rgba($black, 0.2);
  box-shadow: 0 1px 3px rgb($black, 10%);
}

.two-col.wp-block-media-text > div {
  @include tablet {
    column-count: 2;
  }
}

.page.going-green {
  .wp-block-columns {
    gap: 2.5rem;

    @include tablet {
      margin: 0 auto 2.5rem;
    }

    @include laptop {
      width: 85%;
    }

    @include wide {
      max-width: 76%;
    }
  }
}

.box {
  background-color: #cbdfbb;
  padding: 15px;
  box-shadow: 0 0 3px #3e3e3e;
  border-radius: 5px;
}

table.contact-info {
  td {
    vertical-align: top;
    font-size: 20px;
    min-width: 90px;
    padding: 10px 0;
    word-break: break-word;
  }
}

body.template-forms {
  .left-col,
  .right-col {
    border-bottom: 2px solid purple;
    margin-bottom: 50px;
    padding-bottom: 50px;

    @include laptop {
      flex: 0 0 50%;
    }
  }

  .left-col {
    @include laptop {
      border-right: 2px solid purple;
    }
  }
}
