.hero {
  min-height: 250px;
  background-size: cover;
  background-position: center;

  @include tablet {
    min-height: 400px;
  }
}

.hero-content {
  padding: 20px 0 83px;
  background-color: rgba($black, 0.9);

  @include laptop {
    padding: 240px 0 116px;
    margin: 0 auto;
    max-width: 834px;
    background-color: rgba($black, 0);
  }

  .blackbg,
  .whitebg {
    font-size: 27px;
    letter-spacing: -0.3px;
    color: #f7f7f7;

    @include laptop {
      font-size: 40px;
    }
  }

  .blackbg {
    font-weight: 400;
    max-width: 700px;

    @include laptop {
      padding: 5px 10px;
      margin-right: 27px;
      margin-bottom: 10px;
      float: right;
      line-height: 1.25;
      background-color: rgba($black, 0.8);
    }
  }

  .whitebg {
    position: relative;
    z-index: 2;
    display: inline-block;
    color: #f7f7f7;

    @include laptop {
      color: $black;
      background-color: rgba($white, 0.8);
      font-weight: 500;
      line-height: 1.4;
      padding: 5px 15px;
    }

    &::before {
      @include laptop {
        content: "";
        display: inline-block;
        background: url('../images/left-boxes.webp') center no-repeat;
        top: -14px;
        left: -42px;
        height: 90px;
        width: 86px;
        position: absolute;
        z-index: -1;
      }
    }

    &::after {
      @include laptop {
        content: "";
        display: inline-block;
        background: url('../images/right-boxes.webp') center no-repeat;
        height: 89px;
        width: 96px;
        position: absolute;
        right: -26px;
        top: -14px;
        z-index: -1;
      }
    }
  }

  .dot {
    line-height: 28%;
    font-size: 85px;
    position: relative;
    top: -11px;
  }

  a[href^="tel:"] {
    color: $white !important;

    @include laptop {
      color: $black !important;
    }
  }
}
