.green-btn {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  background-color: #98ad7c;
  padding: 17px 20px;
  text-transform: uppercase;
  transition: 0.3s;
  display: inline-block;

  &:hover {
    color: #fff;
    background-color: #b7d194;
  }
}

.purple-btn {
  background: linear-gradient(to bottom, rgba(146, 90, 169, 1) 0%, rgba(87, 45, 85, 1) 100%);
  border-color: rgba(0, 0, 0, 0.15) rgba(0, 0, 0, 0.15) rgba(0, 0, 0, 0.25);
  color: #fff;
  font-size: 22px;
  font-weight: 600;
  letter-spacing: -0.5px;
  padding: 3px 18px;
  box-shadow: 2px 2px #333;
  border-radius: 4px;
  display: inline-block;

  @include tablet {
    padding: 3px 42px;
  }

  &:hover {
    background: linear-gradient(to bottom, rgba(152, 99, 173, 1) 0%, rgba(99, 51, 96, 1) 100%);
    color: #fff;
  }

  &:focus,
  &:active {
    background: #76447c;
    box-shadow: 1px 1px #333;
    position: relative;
    top: 1px;
    right: -1px;
  }
}

.wp-block-button__link {
  @extend .purple-btn;
}

.btn-primary {
  background-color: #7a4278;
  color: $white;
  font-size: 18px;
  font-weight: 700;
  font-family: roboto, sans-serif;
  border-radius: 0;
  padding: 2px 10px;

  &:hover {
    background-color: #894d86;
  }
}
